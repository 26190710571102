<template>
  <div class="dashboard__container">
    <div class="dashboard__container--body" v-if="user">
       <div class="dashboard__container--body--col">
          <div class="whiteBack">
            <h4 class="pb-3">Generate Employee Offer Letter</h4>
            <div class="mb-3">
              <label for="offerDate">Offer Date:</label>
              <input type="date" placeholder="offer date" v-model="offerDate" />
            </div>
            <div class="mb-3">
              <label for="startDate">Start Date:</label>
              <input type="date" placeholder="start date" v-model="startDate" />
            </div>
            
            <div class="mb-3" v-if="jobs.length >= 1">
              <label for="pickDate">Position:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="jobs"
                  v-model="position"
                  >
                </v-select>
              </div>
              
              <div>
                or:
              <input type="text" placeholder="add position" v-model="position.title"/>
              </div>
            <div class="mb-3" v-if="venues.length >= 1">
              <label for="pickDate">Location:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="venues"
                  v-model="location"
                  >
                </v-select>
              </div>
            <div class="mb-3">
              <label for="rate">Rate:</label>
              <input type="text" placeholder="rate" v-model="rate" />
            </div>
            <div class="mb-5">
              <label for="more">Additional Text:</label>
              <textarea name="more" id="more" cols="30" rows="2" v-model="more"></textarea>
            </div>
            <button class="btn ma-2 btn__primary" @click="print()" :disabled="!valid">Print Letter</button>
         </div>
      </div>
      <div class="dashboard__container--body--col"  id="printMe">
        <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/logos%2FJump%20Logo%20Official%20(2).png?alt=media&token=b05c3dc2-22d7-4f2e-8085-2e1cfbdb47b9" alt="" style="width:200px; margin-bottom: 10px;">
        <div class="mb-3">
          {{offerDate | moment("dddd, MMMM Do YYYY")}}
        </div>
        <div class="mb-3">
          <p>{{user.firstName}} {{user.lastName}}
            <br>
            <span v-if="user.address">{{user.address.street_number}} {{user.address.street}}</span>
            <br v-if="user.address && user.address.unit">
            <span v-if="user.address && user.address.unit">{{user.address.unit}}</span>
            <br>
            <span v-if="user.address">{{user.address.city}}, {{user.address.state}} {{user.address.zip}}</span>
            </p>
        </div>
        <div class="mb-3">
          <p>Dear {{user.firstName}} {{user.lastName}},</p>
          <p v-if="position && location">We are excited to offer you the position of {{position.title}}. Your first day of employment will be {{startDate | moment("dddd, MMMM Do YYYY")}} and your work location is {{location.title}}, {{location.address.street_number}} {{location.address.street}}, {{location.address.city}}, {{location.address.state}} {{location.address.zip}}
          <p>{{position.description}}</p>
          <p>Your hourly pay is ${{rate}} per hour.</p>
          <p>You will earn 1 hour of paid time off (PTO) for every 30 hours worked. PTO will begin to accrue 90 days after your start date. The maxium PTO that can be accrued is 48 hours.</p>
          <p>Your employment with Jump Employee Services LLC will be on an "at-will" basis, which means you and the company are free to terminate the employment relationship at any time, with or without cause, and with or without advance notice.</p>
          <p v-if="more">{{more}}</p>
          <p>Thank you for working with us. We are excited to have you on our team.</p>

          <div style="margin-bottom:24px;"></div>
          <div style="padding-bottom:48px;">Accepted By: </div>
          <div style="border-bottom:2px solid; width:300px;"></div>
          {{user.firstName}} {{user.lastName}}     -     DATE

          <div style="margin-bottom:48px;"></div>
          <span style=" border-bottom:2px solid; width:auto;">
            <img src="https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/signature3.png?alt=media&token=4185d720-2561-4fde-bd5d-108b63da5f3b" alt="" style="width:172px; margin-left:-4px; margin-bottom:-8px;">
            <span style="margin-left:20px;">{{offerDate | moment("MM/DD/YYYY")}}</span>
          </span>

          <div style="padding-bottom:12px;"> </div>
          <div>Jennifer Poma</div>
          <div>CEO</div>
          <div>Jump Employee Services LLC</div>
        </div>
        
        
      </div>
      
    </div>
    <div class="dashboard__container--body" v-if="user">
      <div class="dashboard__container--body--col">
        <div class="flex flex-column whiteBack">
          <label class="mb-2" for="fileTitle">Upload Offer Letter:</label>
          <input type="file" ref="fileInputTip" id="offerLetter" accept="image/*,application/pdf,.doc" @change="previewImage" hidden>
          <div>
            <button @click="chooseFile()" class="btn btn__primary mt-2 btn__small">Upload File</button>
          </div>
          </div>
          <div class="mb-3">
          <progress :value="uploadValue" max="100" v-if="showBar"></progress>
          </div>
      </div>
    </div>

    
    <div class="dashboard__container--body" v-if="user">
      <div v-if="user && user.letters && user.letters.length >= 1" class="pt-3" style="width:100%;">
        <vue-good-table
          :columns="columns"
          :rows="user.letters"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'url'">
              <a :href="props.row.url" target="_blank">View <i class="fas fa-external-link"></i></a>
            </span>
            <span v-else-if="props.column.field == 'date'">
              <span v-if="props.row.date">
               {{formatDate(props.row.date)}}
              </span>
            </span>
            <span v-else-if="props.column.field == 'extras'">

                <i class="fas fa-trash" @click="deleteUploadedFile(props.row, props.index)"></i>

            </span>
            <!-- <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span> -->
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>



<script>
import { mapState } from 'vuex'
import router from '@/router'
import * as moment from 'moment'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['user'],
  data: () => ({ 
    output: null,
    startDate: null,
    offerDate: null,
    position: {},
    location: null,
    rate: '',
    more: '',
    imageData: null,
    uploadValue: 0,
    showBar:false,
    columns: [
      {
        label: 'Upload Date',
        field: 'date',
        sortable: false
      },
      {
        label: 'Url',
        field: 'url',
        sortable: false
      },
      {
        label: 'Delete',
        field: 'extras',
        sortable: false
      },
    ]
  }),
  name: 'offerLetter',
  computed: {
    ...mapState(['userProfile', 'currentUser', 'jobs', 'venues']),
    valid() {
      if (this.startDate && this.offerDate && this.position && this.location && this.rate) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    if (!this.jobs || this.jobs.length < 1) {
      this.$store.dispatch("getJobsState")
    }
    if (!this.venues || this.venues.length < 1) {
      this.$store.dispatch("getVenues")
    }
  },
  methods: {
    async print () {
      await this.$htmlToPaper('printMe');
    },
    chooseFile() {
      document.getElementById("offerLetter").click()
    },
    previewImage(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadFile()
    },
    onUploadFile() {
      console.log('onUpload')
      this.showBar = true
      let user = this.user
      let date = new Date();
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          var docRef = fb.usersCollection.doc(user.id)
          docRef.update({
            letters: fb.firestore.FieldValue.arrayUnion({
              date: date,
              url: downloadURL
            })
          });
          return user.letters.push({
            date: date,
            url: downloadURL
          })
        })
        this.showBar = false
      })
      this.imageData = null
      this.$refs.fileInputTip.value=null
    },
    deleteUploadedFile(u, index) {
      console.log(u)
      let user = this.user
      user.letters.splice(index, 1)
      this.$store.dispatch('updateUser', user)
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('MM/DD/YYYY - HH:mm A')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
  },
}

</script>